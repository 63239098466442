import React from 'react';
import { IconCard } from '../../../common/components/icon-card';
import { ExternalLink } from '../../../common/components/link/external-link';

export const CopyrightCard = () => (
	<IconCard title="Copyright Notices" src="/img/copyright.svg">
		<ul>
			<li>
				<b>User Data</b>{' - All user pictures and data is fake and '}
				{'randomly generated by open-source third-party service '}
				<ExternalLink
					href="https://randomuser.me/"
					title="randomuser.me"
					event="copyright"
				/>
				{'. Additional license information by link '}
				<ExternalLink
					href="https://randomuser.me/copyright"
					title="randomuser.me/copyright"
					event="copyright"
				/>
				{'.'}
			</li>
			<li>
				<b>Post</b> -{' All pictures used in PostData is free to use and was fetched from '}
				<ExternalLink
					href="https://unsplash.com/"
					title="unsplash.com"
					event="copyright"
				/>
				{'. Additional license information by link '}
				<ExternalLink
					href="https://unsplash.com/license"
					title="unsplash.com/license"
					event="copyright"
				/>
				{'.'}
			</li>
		</ul>
	</IconCard>
);
