export const FEATURES = [
	{
		title: 'REST API',
		icon: 'gear.svg',
		text: 'DummyAPI is a <b>RESTful online fake API</b>, publicly accessible via https.'
	},
	{
		title: 'GraphQL',
		icon: 'graphql.svg',
		text: 'Access static fake data via the <b>GraphQL</b> interface.'
	},
	{
		title: 'Cross platform',
		icon: 'code.svg',
		text: '<b>application/json</b> format, Mock JSON dummy API server. Available via REST endpoints or GraphQL.'
	},
	{
		title: 'Data relations',
		icon: 'relation.svg',
		text: 'Various data structures and types, with a different type of relations. Including <b>images, text, links, map location data, datetime,</b> and <b>emails</b>.'
	},
	{
		title: '24/7 uptime',
		icon: 'calendar.svg',
		text: 'DummyAPI deployed on <b>Google Cloud Platform</b>, which provides <b>99.9% uptime</b> and <b>fast response</b> time.'
	},
	{
		title: 'Free to use',
		icon: 'coins.svg',
		text: 'Free for all users, up to <b>500</b> requests per day on a beta phase. Support us on Patreon to get unlimited access.'
	}
];
