import React from 'react';
import { Card } from '../../common/components/card';
import { Loader } from '../../common/components/loader';
import { UnderConstruct } from '../../common/components/under-construct';
// import { Chart } from '../../common/components/chart';
// import { useFetchApi } from '../../common/hooks/use-fetch-api';
import { Formatter } from '../../common/utils/formatter';


export const UsageChart = () => {
	// const { loading, data } = useFetchApi('/statistic')

	// const chartData = React.useMemo(() => {
	// 	if (!data) return [];
	//
	// 	const DAY = 1000 * 60 * 60 * 24;
	// 	const today = new Date().setHours(0, 0, 0, 0);
	// 	const _data = [];
	//
	// 	for (let i = 100; i >= 0; i--) {
	// 		const currentDay = new Date(today - (DAY * i));
	// 		const dateStr = Formatter.dateDashStr(currentDay);
	// 		const date = +Formatter.parseDateDashStr(dateStr);
	//
	// 		_data.push({ date, value: data[dateStr] || 0 });
	// 	}
	//
	// 	return _data;
	// }, [data]);

	return (
		<Card>
			<div className="text-2xl font-medium text-gray-900">API Call's per day</div>
			<small>Last 100 days statistic of API calls per each day.</small>
			<div className="h-32 font-mono">
				<UnderConstruct />
			</div>

			{/*{loading && (<Loader />)}*/}
			{/*{!loading && (*/}
			{/*	<div className="h-32 font-mono text-xs">*/}
			{/*		<Chart chartData={chartData} />*/}
			{/*	</div>*/}
			{/*)}*/}
		</Card>
	);
};
