import React from 'react';
import { Analytics } from '../../../common/utils/analytics';

const PATREON_HREF = 'https://www.patreon.com/bePatron?u=39154562';
const PATREON_IMG = 'https://c5.patreon.com/external/logo/become_a_patron_button@2x.png';

export const PatreonButton = () => {
	function handleClick() {
		Analytics.link('patreon', PATREON_HREF);
	}

	return (
		<div className="mt-3 flex justify-center">
			<a
				href={PATREON_HREF}
				target="_blank"
				className="w-40 block"
				onClick={handleClick}
			>
				<img src={PATREON_IMG} alt="patreon btn" />
			</a>
		</div>
	);
}

