import React from 'react';
import { Card } from '../../common/components/card';
import { Analytics } from '../../common/utils/analytics';
import { affiliates } from '../constants/affiliates';


export const Ads: React.FC = () => {
	const [randomNumber, setRandomNumber] = React.useState(0);

	React.useEffect(() => {
		setRandomNumber(Math.round(Math.random()));
	}, []);

	const adsItem = React.useMemo(() => {
		const ads = [];

		for (let i=0; i<affiliates.length; i++) {
			for (let j=0; j<affiliates[i].items.length; j++) {
				ads.push({
					key: affiliates[i].items[j].key,
					parentKey: affiliates[i].key,
					link: (affiliates[i].items[j] as any).link || affiliates[i].link,
					src: affiliates[i].items[j].src,
				});
			}
		}

		return ads[randomNumber];
	}, [randomNumber]);

	function handleClick() {
		Analytics.ads(adsItem.key);
	}

	return (
		<Card className="mb-4 mt-4">
			<div className="-m-4 p-0.5">
				<div key={adsItem.key}>
					<a
						href={adsItem.link}
						target="_blank"
						rel="noopener noreferrer external nofollow"
						style={{ maxHeight: '100px' }}
						onClick={handleClick}
					>
						<img
							className="w-full"
							src={`/img/${adsItem.src}`}
						/>
					</a>
				</div>
			</div>
		</Card>
	);
}
