import React from 'react';
import { IconCard } from '../../../common/components/icon-card';
import { ExternalLink } from '../../../common/components/link/external-link';


export const SideProjectsCard = () => (
	<IconCard title="Side Projects" src="/img/build.svg">
		<b>RGBTOHEX.PAGE</b>{' - Feel free to check our side project. This site cover everything about '}
		<b>color</b> in development.<br />
		{'Explore Color Models(RGB, CMYK, HSL) and various of color palettes(Brand Colors, Color Bit Depth, Color Blind Palettes) '}
		<ExternalLink
			href="https://rgbtohex.page"
			event="rgbtohex"
			doFollow
		/>
	</IconCard>
);
