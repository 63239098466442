
export const affiliates = [
	{
		key: 'templatemonster-html',
		link: 'https://bit.ly/38tT1t2',
		items: [
			{
				key: 'templatemonster-html-v-1',
				src: 'z2bypw2xvw688s6vj.jpg',
			}
		]
	},
	// {
	// 	key: 'monsterone',
	// 	link: 'https://monsterone.com/?aff=dummyapi&a_bid=7cb158fb&chan=dummyapi',
	// 	items: [
	// 		{
	// 			key: 'monsterone-v-2',
	// 			src: 'monsterone-v-2.jpg',
	// 		},
	// 		{
	// 			key: 'monsterone-v-3',
	// 			src: 'monsterone-v-3.jpg',
	// 		},
	// 		{
	// 			key: 'monsterone-v-4',
	// 			src: 'monsterone-v-4.jpg',
	// 		}
	// 	]
	// },
	{
		key: 'bluehost',
		link: 'https://bit.ly/3t29Jcg',
		items: [
			{
				key: 'bluehost-v-1',
				src: 'za6wya6ceuqer8qgq.png'
			}
		]
	},
	// {
	// 	key: 'fiverr',
	// 	items: [
	// 		{
	// 			key: 'fiverr-v-1',
	// 			link: 'https://fvrr.co/2WGY7z9',
	// 			src: 'fiverr-v-1.jpg'
	// 		},
	// 		{
	// 			key: 'fiverr-v-2',
	// 			link: 'https://fvrr.co/2Wz9Hgo',
	// 			src: 'fiverr-v-2.jpg'
	// 		},
	// 		{
	// 			key: 'fiverr-v-3',
	// 			link: 'https://fvrr.co/3yyNKLh',
	// 			src: 'fiverr-v-3.jpg'
	// 		},
	// 		{
	// 			key: 'fiverr-v-4',
	// 			link: 'https://fvrr.co/3kyVMP2',
	// 			src: 'fiverr-v-4.jpg'
	// 		},
	// 		{
	// 			key: 'fiverr-v-5',
	// 			link: 'https://fvrr.co/3zxeSvi',
	// 			src: 'fiverr-v-5.jpg'
	// 		},
	// 		{
	// 			key: 'fiverr-v-6',
	// 			link: 'https://fvrr.co/3zz2F9y',
	// 			src: 'fiverr-v-6.jpg'
	// 		},
	// 		{
	// 			key: 'fiverr-v-7',
	// 			link: 'https://fvrr.co/3jxWKfg',
	// 			src: 'fiverr-v-7.jpg'
	// 		},
	// 		{
	// 			key: 'fiverr-v-8',
	// 			link: 'https://fvrr.co/3jx3TN2',
	// 			src: 'fiverr-v-8.jpg'
	// 		},
	// 		{
	// 			key: 'fiverr-v-9',
	// 			link: 'https://fvrr.co/3jwXam1',
	// 			src: 'fiverr-v-9.jpg'
	// 		},
	// 		{
	// 			key: 'fiverr-v-10',
	// 			link: 'https://fvrr.co/3zAwark',
	// 			src: 'fiverr-v-10.jpg'
	// 		},
	// 		{
	// 			key: 'fiverr-v-11',
	// 			link: 'https://fvrr.co/3DzGfHx',
	// 			src: 'fiverr-v-11.jpg'
	// 		}
	// 	],
	// }
];
