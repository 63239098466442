import React from 'react';

export const Error = () => (
	<div className="text-red-600 flex">
		<div className="mr-4">
			<img src="/img/danger.svg" className="w-6" alt="danger" />
		</div>
		Something wrong with API. We are investigating a problem.
	</div>
);
