import React from 'react';
import { UserContext } from '../../../app/contexts/user';
import { ButtonLink } from '../../../common/components/link/button-link';
import { IndexSection } from '../../common/components/index-section';

export const HeroSection: React.FC = () => {
	const { user } = React.useContext(UserContext);

	const accountLink = user ? '/account' : '/sign-in';

	return (
		<IndexSection>
			<div className="pb-10 flex justify-between flex-col	md:flex-row ">
				<div>
					<h1 className="mb-4 text-white">Dummy API</h1>
					<h2 className="mb-2 text-white">
						Ready to use API Service full of dummy fake data
					</h2>
					<div className="text-white font-light">
						Data sandbox API for playing around with real users and post data.<br />
						Feel free to use it in your demo projects, tutorials, or testing tasks.<br />
						Over <b>100</b> fake users, <b>800+</b> posts and pictures, <b>1k+</b> comments.<br />
						There are two types of API interfaces available: <b>REST data API</b> endpoints and <b>GraphQL API</b>.
					</div>
				</div>

				<div className="pt-4 flex justify-center md:flex-col md:items-end md:pl-4 flex-wrap">
					<ButtonLink href={accountLink} title="Account Dashboard" />
					<ButtonLink href="/explorer" title="Explore API Live" />
					<ButtonLink href="/docs" title="API Documentation" />
				</div>
			</div>
		</IndexSection>
	);
};
