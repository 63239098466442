import React from 'react';
import { Card } from '../../../common/components/card';

type Props = {
	title: string;
	text: string;
};

export const UseCaseCard: React.FC<Props> = ({ title, text }) => (
	<Card className="mt-4">
		<div className="w-5 h-5 mr-2 float-left">
			<img src="/img/check.svg" alt="check" />
		</div>
		<b className="text-gray-900">{title}</b> - {text}
	</Card>
);
