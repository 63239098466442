import React from 'react';
import { IndexSection } from '../../common/components/index-section';
import { UseCaseCard } from './use-case-card';
import { CARDS } from '../configs/cards';


export const UseCasesSection: React.FC = () => (
	<IndexSection title="Use cases">
		<div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
			<div>
				{CARDS[0].map(({ title, text }) => (
					<UseCaseCard key={title} title={title} text={text} />
				))}
			</div>
			<div>
				{CARDS[1].map(({ title, text }) => (
					<UseCaseCard key={title} title={title} text={text} />
				))}
			</div>
		</div>
	</IndexSection>
);
