import Head from 'next/head';
import React from 'react';
import { IndexPage } from '../modules/index';

const Index = () => (
	<React.Fragment>
		<Head>
			<title>Dummy API: Fake api full of users, images, post, comments etc</title>
			<meta name="description" content="Full cycle REST/GraphQL production API server with fake data, 100 Users, over 800 posts, 1000 comments, 24/7 available for your experiments" />
		</Head>
		<IndexPage />
	</React.Fragment>
);

export default Index;
