import React from 'react';
import { Ads } from '../../../ads/components';
import { Card } from '../../../common/components/card';
import { IndexSection } from '../../common/components/index-section';
import { FEATURES } from '../configs/features';


export const FeaturesSection: React.FC = () => (
	<IndexSection title="Features">
		<div className="my-4 grid grid-cols-1 gap-4 md:grid-cols-3">
			{FEATURES.map(feature => (
				<Card key={feature.title}>
					<img
						className="h-8 mx-auto my-2 float-left mr-3"
						src={`/img/${feature.icon}`}
						alt={feature.title}
					/>
					<h3 className="inline">{feature.title} - </h3>
					<div
						className="text-sm text-gray-600 inline"
						dangerouslySetInnerHTML={{ __html: feature.text }}
					/>
				</Card>
			))}
		</div>
		<Ads />
	</IndexSection>
);
