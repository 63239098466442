import axios from 'axios';
import React from 'react';
import { DATA_API } from '../constants/api';
import { axiosHandle } from '../utils/axios-handle';

const headers = { 'app-id': '6112dc7c3f812e0d9b6679dd' };

export const useFetchData = (url: string, type: string | null = null) => {
	const [data, setData] = React.useState<any>(null);
	const [dataType, setDataType] = React.useState<string | null>(null);
	const [loading, setLoading] = React.useState<boolean>(true);
	const [status, setStatus] = React.useState<boolean>(false);
	const [time, setTime] = React.useState<number | null>(null);

	React.useEffect(() => {
		let startTime = +new Date();
		let _type = type;

		setDataType(null);
		setLoading(true);
		setData(null);
		setStatus(false);

		const query = axios.get(`${DATA_API}${url}`, { headers });

		axiosHandle(query, 'While getting explorer data', null)
			.then(respData => {
				if (type === _type) {
					setTime(+new Date() - startTime);
					setStatus(!!respData);
					setData({ respData, type: _type });
					setLoading(false);
					setDataType(type);
				}
			});
	}, [url]);

	return { loading, data, status, time, dataType };
};
