export const CARDS = [
	[
		{
			title: 'Education, Learning',
			text: 'suitable fake API for any types of tutorials. We try to cover all possible cases that you need to work on real production projects.'
		},
		{
			title: 'Free Image API',
			text: 'We have a lot of dummy data. You can simply use DummyAPI as a free image API, including metadata for every image. Tags, text and a source link of the image source.'
		},
		{
			title: 'Demo page, Portfolio',
			text: 'our non-auth fake API data is perfect for creating a presentation page for libraries and modules. No server-side code, deploying, infrastructure setups, or storing data anymore.'
		}
	],
	[
		{
			title: 'Testing',
			text: 'Dummy API for testing JSON, go deep with Unit tests for Rest API.'
		},
		{
			title: 'Skill evaluation',
			text: 'Want to give your future employee an assignment? Our fake API is great for covering all required areas, starting from simple pagination till auth flow.'
		},
		{
			title: 'Any other cases',
			text: 'We have no restriction for dummy API usage. Data is not real, but close to it, so you have a wide range for play around.'
		}
	]
];
