import React from 'react';
import { Analytics, LinkCategory } from '../../utils/analytics';

type Props = {
	href: string;
	event?: LinkCategory;
	title?: string;
	doFollow?: boolean;
};

export const ExternalLink: React.FC<Props> = ({ href, title, event, doFollow = false }) => {
	const rel = `noopener noreferrer external ${doFollow ? '' : 'nofollow'}`;
	const className = 'text-blue-800 hover:text-purple-800 underline';

	function handleClick() {
		if (event) {
			Analytics.link(event, href);
		}
	}

	return (
		<a
			href={href}
			title={title}
			rel={rel}
			className={className}
			target="_blank"
			onClick={handleClick}
		>
			{title || href}
		</a>
	);
};
