import React from 'react';
import { Card } from '../../common/components/card';
import { Loader } from '../../common/components/loader';

type Props = {
	loading: boolean;
	time: number | null;
};

export const UsageTime: React.FC<Props> = ({ loading, time }) => (
	<Card className="flex flex-col col-span-2 md:col-auto">
		{loading && (<Loader />)}
		{!loading && (
			<React.Fragment>
				<div className="text-2xl font-medium text-gray-900">Response Time</div>
				<small>Average response time in your region.</small>
				<div className="text-4xl font-medium flex-grow flex items-center">
					{time}ms
				</div>
			</React.Fragment>
		)}
	</Card>
);
