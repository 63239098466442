import React from 'react';
import { Ads } from '../../ads/components';
import { IndexSection } from '../common/components/index-section';
import { useFetchData } from '../../common/hooks/use-fetch-data';
import { UsageStatus } from './status';
import { UsageTime } from './time';
import { UsageChart } from './chart';


export const UsageSection = () => {
	const { loading, status, time } = useFetchData('/user?limit=10');

	return (
		<IndexSection title="Usage Statistic">
			<div className="mt-4 grid grid-cols-2 gap-4">
				<UsageStatus loading={loading} status={status} />
				<UsageTime loading={loading} time={time} />
				<div className="col-span-2">
					<UsageChart />
				</div>
			</div>
			<Ads />
		</IndexSection>
	);
};
