import React from 'react';
import { IconCard } from '../../../common/components/icon-card';
import { ExternalLink } from '../../../common/components/link/external-link';


export const CommunityCard: React.FC = () => (
	<IconCard title="Community" src="/img/collab.svg">
		We are trying to do our best for you.<br />
		That why we create a telegram community to post important updates about project.<br />
		And Telegram Chat where you can report a bug or bring some ideas about improvements.<br />
		<ExternalLink
			href="https://t.me/dummyapi"
			title="DummyAPI Telegram Community"
			event="telegram"
		/>
		<br />
		<ExternalLink
			href="https://t.me/joinchat/gYbZUpgbBWw4ZDY0"
			title="DummyAPI Telegram Chat"
			event="telegram"
		/>
	</IconCard>
);
