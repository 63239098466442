import React from 'react';
import { Card } from '../../common/components/card';
import { Loader } from '../../common/components/loader';
import { Error } from '../../common/components/error';

type Props = {
	status: boolean | null;
	loading: boolean;
};

export const UsageStatus: React.FC<Props> = ({ status, loading }) => (
	<Card className="flex col-span-2 md:col-auto">
		{loading && (<Loader />)}
		{!loading && (
			<React.Fragment>
				<div className="flex-grow">
					<div className="text-2xl font-medium text-gray-900">Status</div>
					<div>
						<small>Deploying on GCF and maintain 99.99% uptime.</small>
						{status && (<div className="text-green-600">API working normally</div>)}
						{!status && (<Error />)}
					</div>
				</div>
				<div className="ml-4 flex-shrink-0 self-center">
					<img
						src={`/img/check-${status ? 'success' : 'warning'}.svg`}
						alt="check"
						className="w-12"
					/>
				</div>
			</React.Fragment>
		)}
	</Card>
);
