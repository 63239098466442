import React from 'react';
import { IndexSection } from '../../common/components/index-section';
import { SideProjectsCard } from './side-projects-card';
import { CopyrightCard } from './copyright-card';
import { ContactUsCard } from './contact-us-card';
import { CommunityCard } from './comunity-card';


export const LinksSections = () => (
	<IndexSection title="Related Links">
		<div className="md:grid md:grid-cols-2 md:gap-4">
			<div>
				<ContactUsCard />
				<CommunityCard />
			</div>

			<div>
				<CopyrightCard />
				<SideProjectsCard />
			</div>
		</div>
	</IndexSection>
);
