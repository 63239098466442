import React from 'react';

type Props = {
	title?: string;
	children: React.ReactNode;
};

export const IndexSection: React.FC<Props> = ({ title, children }) => (
	<article className="my-16">
		{!!title && (
			<h2 className="text-white text-center">{title}</h2>
		)}
		{children}
	</article>
);
