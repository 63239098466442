import React from 'react';
import { Ads } from '../ads/components';
import { HeroSection } from './hero-section/components';
import { FeaturesSection } from './features-section/components';
import { UseCasesSection } from './use-case-section/components';
import { UsageSection } from './usage-section';
import { SponsorsSection } from './sponsors-section/components';
import { LinksSections } from './links-section/components';


export const IndexPage: React.FC = () => (
	<React.Fragment>
		<HeroSection />
		<FeaturesSection />
		<UseCasesSection />
		<UsageSection />
		<SponsorsSection />
		<LinksSections />
	</React.Fragment>
);
