import { AxiosResponse } from 'axios';
import { useToast } from '../hooks/use-toast';
import { Analytics } from './analytics';

export function axiosHandle<T>(
	promise: Promise<AxiosResponse>,
	errorText: string,
	fallbackValue: any,
): Promise<T> {
	const { error } = useToast();

	return promise.then(resp => resp.data).catch((err) => {
		Analytics.exception(`${err.config.url} :: ${err.message}`);
		error(errorText);
		return fallbackValue;
	});
}