import React from 'react';
import { Card } from '../../../common/components/card';
import { IndexSection } from '../../common/components/index-section';
import { PatreonButton } from './patreon-button';


export const SponsorsSection = () => (
	<IndexSection title="Our Sponsors">
		<Card className="mt-4">
			You can become our sponsor on Patreon,
			we will place a logo and a link to your website in current section.<br /><br />
			There two types of sponsorship: <br />
			1) <b>10$+</b> - We will place a link to your website and a logo.<br />
			2) <b>50$+</b> - We will place a link(follow) to your website and a logo on a top of a
			section, and bigger. Also you can provide a small text snippet that describes your company.

			<PatreonButton />
		</Card>
	</IndexSection>
);
