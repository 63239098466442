import React from 'react';
import { ExternalLink } from './external-link';

type Props = {
	email: string;
};

export const EmailLink: React.FC<Props> = ({ email }) => (
	<ExternalLink href={`mailto:${email}`} title={email} event="email" />
);
